<template>
  <div>
    <v-container>
      <v-row style="max-height='1000px'">
        <v-col cols="12" md="8">
          <v-card flat class="mt-3">
            <div class="cardHeader">Personal Details</div>
            <div class="pa-3">
              <v-row>
                <v-col cols="12" md="6">
                  <FieldValue
                    Text="First Name"
                    :value="appData.fName"
                  ></FieldValue>
                </v-col>

                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Last Name"
                    :value="appData.lName"
                  ></FieldValue>
                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-2"></v-divider>
              <v-row>
                <v-col cols="12" md="6">
                  <FieldValue Text="Email" :value="appData.email"></FieldValue>
                </v-col>
                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Mobile"
                    :value="appData.mobile"
                  ></FieldValue>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <v-card flat class="mt-3">
            <div class="cardHeader">Identification</div>
            <div class="pa-3">
              <v-row>
                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Id Proof"
                    :value="appData.idProofType"
                  ></FieldValue>
                </v-col>

                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Id Proof Number"
                    :value="appData.idProofNumber"
                  ></FieldValue>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <v-card flat class="mt-3">
            <div class="cardHeader">Accreditation Details</div>
            <div class="pa-3">
              <v-row>
                <v-col cols="12" md="12">
                  <FieldValue
                    Text="Company"
                    :value="appData.company_name"
                  ></FieldValue>
                </v-col>
                <v-col cols="12" md="6">
                  <!-- <FieldValue
                    Text="Category"
                    :value="appData.companytype_name"
                  ></FieldValue> -->

                  <v-select
                    label="Select Category Type"
                    v-model="appData.companytype_id"
                    :items="CompanyType"
                    item-text="companytype_name"
                    item-value="companytype_id"
                    dense
                    outlined
                    hide-details
                    @change="btnbindjob"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <!-- <FieldValue
                    Text="Job type"
                     @change="btnbindjobtype"
                    :value="appData.jobtype_name"
                  ></FieldValue> -->

                  <v-select
                    label="Select Job Type"
                    v-model="appData.jobtype_id"
                    :items="JobType"
                    item-text="jobtype_name"
                    item-value="jobtype_id"
                    dense
                    outlined
                    hide-details
                   
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="appData.zone"
                    outlined
                    :disabled ="checkenabled"
                    :items="Stand"
                    item-text="stand_name"
                    item-value="stand_id"
                    label="Select Stand/Zone"
                    hide-details
                    attach
                    chips
                    small-chips
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <v-card flat class="mt-3">
            <div class="cardHeader">ID Proof Details</div>
            <div class="pa-3">
              <v-row>
                <v-col cols="12" md="6">
                  <v-card flat class="mt-3">
                    <div class="cardHeader">ID Proof - Front</div>
                    <div class="pa-3 text-lg-center">
                      <viewer :images="[this.$apiPath + appData.picIdFront]">
                        <img
                          :src="this.$apiPath + appData.picIdFront"
                          :key="src"
                          class="imgId"
                          width="100%"
                        />
                      </viewer>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat class="mt-3">
                    <div class="cardHeader">ID Proof - Back</div>
                    <div
                      class="pa-3 text-lg-center"
                      v-if="appData.picIdBack != null"
                    >
                      <viewer :images="[this.$apiPath + appData.picIdBack]">
                        <img
                          :src="this.$apiPath + appData.picIdBack"
                          :key="src"
                          class="imgId"
                          v-if="appData.picIdBack"
                          width="100%"
                        />
                      </viewer>
                    </div>
                    <div
                      class="pa-3 text-lg-center ma-10"
                      style="font-size: 15px; color: red"
                      v-else
                    >
                      Not Uploaded
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card flat class="mt-3">
            <div class="cardHeader">Headshot Picture</div>
            <div class="pa-3 text-lg-center">
              <viewer :images="[this.$apiPath + appData.picHeadShot]">
                <img
                  :src="this.$apiPath + appData.picHeadShot"
                  :key="src"
                  class="imgId"
                />
              </viewer>
              <v-btn
                text
                color="primary"
                class="pa-3 text-lg-center"
                v-if="appData.status_named != 'Printed'"
                @click="btncrop"
              >
                <v-icon left>mdi-crop</v-icon>
                CROP
              </v-btn>
            </div>
          </v-card>

          <!-- :href="$apiPath + appData.covid_certificate" -->

          <!-- <v-card flat class="mt-3">
            <div class="cardHeader">Covid 19 Vaccination Certificate</div>
            <div class="pa-3">
              <v-card flat class="mt-6">
                <div class="text-center pa-2">
                  <v-btn
                    v-if="appData.covid_certificate != null"
                    outlined
                    color="primary"
                    @click="btnviewcertificate"
                    >View Certificate
                    <v-icon right>mdi-book-search-outline</v-icon></v-btn
                  >
                  <div style="font-size: 15px; color: red" v-else>
                    Not Uploaded
                  </div>
                </div>
              </v-card>
            </div>
          </v-card> -->

          <v-card v-if="appstatus != '7'" flat class="mt-3">
            <!-- <div class="cardHeader">Identification</div> -->
            <div class="pa-1">
              <v-row>
                <v-col cols="12" md="12">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="btnsaveonly"
                      >Save Application</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- v-if="appstatus == '2'" -->
    <div
      v-if="appstatus != '7'"
      style="
        z-index: 1300;
        position: sticky;
        bottom: 0px;
        max-width: 700px;
        margin: auto;
      "
    >
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="4">
          <v-select
            label="Select Status"
            v-model="appData.status_id"
            :items="StatusForUpdate"
            item-text="status_name"
            item-value="status_id"
            dense
            outlined
            @change="getreson"
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="6" v-if="appData.status_id != 3">
          <v-select
            label="Select Reason"
            v-model="appData.reason_id"
            :items="appreasonlist"
            item-text="reason_name"
            item-value="reason_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" v-if="appData.status_id == 3">
          <v-select
            label="Select Card Type"
            v-model="appData.card_id"
            :items="CardTypeForUpdate"
            item-text="card_name"
            item-value="card_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnupdate" class="fullwidth">
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="snackbar" timeout="1000" top :color="snackbarcolor">
      {{ snackbartext }}
    </v-snackbar>

    <v-overlay :absolute="absolute" :value="overlay" style="z-index: 10001">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="modelPhoto" max-width="500">
      <v-card flat class="pa-5">
        <div class="content">
          <section class="cropper-area">
            <div class="img-cropper">
              <VueCropper
                ref="cropper"
                :aspect-ratio="22 / 24"
                :src="editpath"
                :key="editpath"
                style="max-height: 400px"
                id="yourImageTag"
              />

              <!-- <VueCropper
                    ref="cropper"
                    :aspect-ratio="11 / 12"
                    :src="editpath"
                    style="max-height: 400px"
                  /> -->
            </div>
          </section>
          <div class="pt-3 text-center">
            <div
              style="
                border: 1px solid #e6e6e6;
                background-color: #fafafa;
                border-radius: 8px;
                padding: 3px 0px;
              "
            >
              <v-btn icon small @click="imageOpt(0)" class="mr-3">
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(1)" class="mr-3">
                <v-icon>mdi-magnify-minus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(2)" class="mr-3">
                <v-icon>mdi-flip-horizontal</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(3)" class="mr-3">
                <v-icon>mdi-rotate-right</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(4)" class="mr-3">
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(5)" class="mr-3">
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(6)" class="mr-3">
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(7)" class="mr-3">
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(8)">
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-card-actions class="pt-4 text-center">
          <v-btn color="error" outlined @click="close">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="closeDialog">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="600"
      scrollable
      v-model="dialogpdf"
      :fullscreen="fullscreen1"
    >
      <v-card flat>
        <v-toolbar flat>
          <div style="font-weight: 600">COVID 19 - Certificate</div>
          <v-spacer></v-spacer>
          <v-btn right icon color="red" @click="makefulldilog"
            ><v-icon>{{ maxiconname }}</v-icon></v-btn
          >
          <v-btn right icon color="red" @click="dialogpdf = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text
          style="height: 600px"
          v-if="this.appData.covid_certificate != null"
        >
          <pdf
            v-if="
              this.appData.covid_certificate.substring(
                this.appData.covid_certificate.length - 3
              ) == 'pdf'
            "
            :src="this.$apiPath + appData.covid_certificate"
            :key="this.$apiPath + appData.covid_certificate"
          >
          </pdf>
          <v-img
            v-else
            :src="this.$apiPath + appData.covid_certificate"
            :key="this.$apiPath + appData.covid_certificate"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
//import Viewer from "v-viewer";
import FieldValue from "./FieldValue";
//import Vue from "vue";
//import axios from "axios";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import pdf from "vue-pdf";

//import moment from 'moment'
//Vue.use(Viewer);
export default {
  // props: { appData: {},appstatus: {}, },
  props: ["appData", "appstatus", "appreasonlist"],
  components: { VueCropper, FieldValue, pdf },
  data: () => ({
    checkenabled: true,
    absolute: true,
    overlay: false,
    maxiconname: "mdi-fullscreen",
    fullscreen1: false,
    dialogpdf: false,
    modelPhoto: false,
    picpathbackup: "",
    picpath: "",
    editpath: "",
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "primary",
    images: ["https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"],
    Stand: [],
    SelStand: "",
    CompanyType: [],
    JobType: [],
    mediaphoto: {
      event_id: "",
      venue_id: "",
      vendor_id: "",
      application_id: "",
      firstName: "",
      photo: "",
    },
    StatusForUpdate: [
      {
        status_id: 3,
        status_name: "Approve",
        status: "O",
        status_named: "Approved",
      },
      {
        status_id: 4,
        status_name: "Amend",
        status: "O",
        status_named: "Amended",
      },

      {
        status_id: 6,
        status_name: "Reject",
        status: "O",
        status_named: "Rejected",
      },
    ],
    StatusMessage: "",
    ResonForUpdate: [],
    CardTypeForUpdate: [],
    src: "",
    Status: "",
    Reson: "",
    PostData: {
      event_id: "",
      eaid: 0,
      companytype_id: "",
      jobtype_id: "",
      venue_id: "",
      vendor_id: "",
      status_id: "",
      reason_id: "",
      card_id: "",
      azone: [],
    },
  }),

  mounted() {
    // alert(appstatus);
    sessionStorage.EVENTID = "1";
    sessionStorage.VENUEID = "1";
    sessionStorage.VENDORID = "1";

    this.bindjobtype(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    //this.bindStatus();
    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindReson(this.appData.status_id);
    this.bindCampanytype(this.$store.state.eventInfo.EventId);


    //this.bindjobforedit(this.appData.companytype_id);

    // this.SelStand = this.appData.zone;
  },

  watch: {
    "appData.companytype_id": function () {
      this.bindjobforedit(this.appData.companytype_id);
    },
  },

  methods: {
    bindjobtype: async function (EventID, VenueID) {
      this.loadingstatus = true;
      await this.$axios
        .get("Stand/GetStandByEidVid/" + EventID + "/" + VenueID)
        .then((res) => {
          this.Stand = res.data.result;
        })
        .catch()
        .finally();
    },

    btnbindjob: function () {
      this.appData.jobtype_id = "";
      // alert(this.appData.jobtype_id);
      //this.bindjobforedit1(this.appData.companytype_id);
    },

    makefulldilog: function () {
      this.fullscreen1 = !this.fullscreen1;
      if (this.fullscreen1 == true) {
        this.maxiconname = "mdi-fullscreen-exit";
      } else {
        this.maxiconname = "mdi-fullscreen";
      }
    },

    btnbindjobtype: function () {
      // this.appData.jobtype_id = "";
      this.bindjobforedit(this.appData.companytype_id);
    },

    btncrop: function () {
      this.$refs.cropper = "";

      this.editpath = this.$apiPath + this.appData.picHeadShot;
      //"/DNA-Logo-mono.png";
      //alert(this.editpath);
      this.modelPhoto = true;
    },

    close: function () {
      this.modelPhoto = false;
    },
    btnviewcertificate: function () {
      this.dialogpdf = true;
    },

    closeDialog: async function () {
      // alert(sessionStorage.COMPANYID);
      this.picpathbackup = this.$refs.cropper
        .getCroppedCanvas({
          width: 220,
          height: 240,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg", 1);
      this.picpath = this.$refs.cropper
        .getCroppedCanvas({
          width: 220,
          height: 240,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg", 1);
      this.mediaphoto.event_id = this.$store.state.eventInfo.EventId;
      this.mediaphoto.venue_id = this.$store.state.eventInfo.VenueId;
      this.mediaphoto.vendor_id = this.appData.vendor_id;
      this.mediaphoto.application_id = this.appData.application_id;
      this.mediaphoto.firstName = this.appData.fName;

      this.mediaphoto.photo = this.picpath.split(",")[1];
      console.log(this.mediaphoto);
      await this.$axios
        .put("Application/UploadHead", this.mediaphoto)
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("Success", "Photo Croped!");
            this.appData.picHeadShot = res.data.result;
          }
        })
        .catch()
        .finally();
      this.close();

      this.modelPhoto = false;
    },

    imageOpt: function (bIndex) {
      switch (bIndex) {
        case 0:
          this.$refs.cropper.relativeZoom(0.2);
          break;
        case 1:
          this.$refs.cropper.relativeZoom(-0.2);
          break;
        case 2:
          // const dom = this.$refs.cropper;
          // let scale = dom.getAttribute("data-scale");
          // scale = scale ? -scale : -1;
          // this.$refs.cropper.scaleX(scale);
          // dom.setAttribute("data-scale", scale);
          break;
        case 3:
          this.$refs.cropper.rotate(90);
          break;
        case 4:
          this.$refs.cropper.move(-10, 0);
          break;
        case 5:
          this.$refs.cropper.move(10, 0);
          break;
        case 6:
          this.$refs.cropper.move(0, -10);
          break;
        case 7:
          this.$refs.cropper.move(0, 10);
          break;
        case 8:
          this.$refs.cropper.reset();
      }
    },

    bindStatus: async function () {
      await this.$axios.get("Status").then((res) => {
        this.StatusForUpdate = res.data.result;
      });
    },

    bindCartype: async function (event_id) {
      await this.$axios.get("Cards/GetByEventId/" + event_id).then((res) => {
        this.CardTypeForUpdate = res.data.result;
      });
    },

    bindCampanytype: async function (event_id) {
      await this.$axios
        .get("CompanyType/GetByEventId/" + event_id)
        .then((res) => {
          this.CompanyType = res.data.result;
        });
    },

    bindjobforedit: async function (cat_id) {
      await this.$axios
        .get("JobType/GetByCompanyTypeId/" + cat_id)
        .then((res) => {
          this.JobType = res.data.result;
        });

if(this.$store.state.deadline_set == "Y")
{
  //alert("HI");
  this.checkenabled=false;
}
else
{
this.checkenabled=true;
}

    },

    bindjobforedit1: async function (cat_id) {
      await this.$axios
        .get("JobType/GetByCompanyTypeId/" + cat_id)
        .then((res) => {
          this.JobType = res.data.result;
          this.JobType.splice(0, 0, {
            jobtype_id: "0",
            jobtype_name: "Select Job type",
          });
          this.appData.jobtype_id = "0";
        });
    },

    btnsaveonly: async function () {
      this.overlay = true;
      this.PostData.event_id = this.$store.state.eventInfo.EventId;
      this.PostData.venue_id = this.$store.state.eventInfo.VenueId;
      this.PostData.vendor_id = this.appData.vendor_id;
      this.PostData.status_id = this.appData.status_id;
      this.PostData.companytype_id = this.appData.companytype_id;
      this.PostData.jobtype_id = this.appData.jobtype_id;
      this.PostData.eaid = this.$store.state.eventInfo.EventAdminID;

      if (this.appData.status_id == "3") {
        this.PostData.reason_id = "0";
        this.PostData.card_id = this.appData.card_id;
        this.PostData.mname = this.appData.mName;
      } else {
        this.PostData.card_id = "0";
        this.PostData.reason_id = this.appData.reason_id;
        this.PostData.mname = "";
      }

      // if (this.appData.jobtype_id == "") {
      //   this.showSnackbar("error", "Select Jobtype !");
      //   return;
      // }

      this.PostData.azone = this.appData.zone;
      this.StatusMessage = "Application Saved Only";
      await this.$axios
        .put(
          "Application/ChangeAppStatus/" + this.appData.application_id,
          this.PostData
        )
        .then((res) => {
          if (res.data.status == "Success") {
            // this.Reload(sessionStorage.EVENTID, sessionStorage.VENUEID);
            this.showSnackbar("success", this.StatusMessage);
            this.overlay = false;
          } else {
            if (res.data.message == "ALREADY EXISTS") {
              this.showSnackbar("error", "Refrence number already exists !");
              this.overlay = false;
            } else {
              this.showSnackbar("error", res.data.message);
              this.overlay = false;
            }
          }
        })
        .catch()
        .finally();
    },

    btnupdate: async function () {
      // alert(this.appData.status_id + "/reasonid-" + this.appData.reason_id + "/cardid-" + this.appData.card_id);
      //this.appData.jobtype_id="";
      if (this.appData.status_id == "2") {
        this.showSnackbar("error", "Select Status !");
        return;
      }

      if (this.appData.status_id == "3") {
        if (this.appData.card_id == "0") {
          this.showSnackbar("error", "Select Card !");
          return;
        }
      }

      if (this.appData.status_id != "3") {
        if (this.appData.reason_id.length <= 0) {
          this.showSnackbar("error", "Select Reason !");
          return;
        }
      }

      if (this.appData.jobtype_id.length <= 0) {
        this.showSnackbar("error", "Select Jobtype !");
        return;
      }

      if (this.appData.status_id == "3") {
        this.StatusMessage = "Application Approved";
      } else if (this.appData.status_id == "4") {
        this.StatusMessage = "Application Amended";
      } else {
        this.StatusMessage = "Application Rejected";
      }

      this.PostData.event_id = this.$store.state.eventInfo.EventId;
      this.PostData.venue_id = this.$store.state.eventInfo.VenueId;
      this.PostData.vendor_id = this.appData.vendor_id;
      this.PostData.status_id = this.appData.status_id;
      this.PostData.companytype_id = this.appData.companytype_id;
      this.PostData.jobtype_id = this.appData.jobtype_id;
      this.PostData.eaid = this.$store.state.eventInfo.EventAdminID;

      // this.PostData.reason_id= this.appData.reason_id;
      // this.PostData.card_id= this.appData.card_id

      if (this.appData.status_id == "3") {
        this.PostData.reason_id = "0";
        this.PostData.card_id = this.appData.card_id;
      } else {
        this.PostData.card_id = "0";
        this.PostData.reason_id = this.appData.reason_id;
      }
      //alert(this.PostData.jobtype_id);
      if (this.appData.jobtype_id == "") {
        this.showSnackbar("error", "Select Jobtype !");
        return;
      }

      // if(this.appData.reason_id==""){
      // this.PostData.reason_id ="0";
      //       }
      //       else
      //       {
      // this.PostData.reason_id = this.appData.reason_id;
      //       }

      //          if(this.appData.card_id==""){
      // this.PostData.card_id ="0";
      //       }
      //       else
      //       {
      // this.PostData.card_id = this.appData.card_id;
      //       }

      this.PostData.azone = this.appData.zone;

      console.log(this.PostData);
      // console.log(this.appData.application_id);
      this.overlay = true;
      await this.$axios
        .put(
          "Application/ChangeAppStatus/" + this.appData.application_id,
          this.PostData
        )
        .then((res) => {
          if (res.data.status == "Success") {
            // this.Reload(sessionStorage.EVENTID, sessionStorage.VENUEID);
            this.showSnackbar("success", this.StatusMessage);
          }
        })
        .catch()
        .finally(() => {
          this.overlay = false;
        });
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    bindReson: async function (StatusID) {
      await this.$axios.get("Reason/GetByStatusID/" + this.$store.state.eventInfo.EventId + "/" + StatusID).then((res) => {
        this.appreasonlist = res.data.result;
      });
    },

    getreson: function () {
      // alert(this.SelstatusForUpade);
      this.appData.reason_id = "";
      this.appreasonlist = [];
      this.bindReson(this.appData.status_id);
    },

    appsave: function () {
      // alert(this.appData.zone);
    },
  },
};
</script>

<style scoped>
.imgId {
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  cursor: pointer;
  height: 200px;
}
</style>